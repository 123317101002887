var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cs-style", attrs: { id: "wrapper" } },
    [
      _c("Message"),
      _c(
        "div",
        { staticClass: "gray-bg dashbard-1", attrs: { id: "page-wrapper" } },
        [
          _c("div", { staticClass: "border-bottom" }, [_c("Nav")], 1),
          _c("div", { staticClass: "page-content-view" }, [
            _c(
              "div",
              {
                staticClass: "wrapper wrapper-content",
                staticStyle: { "min-height": "calc(100% - 100px)" },
              },
              [_c("router-view")],
              1
            ),
            _c("div", { staticClass: "wrapper-footer" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "bottom-logo" },
                [
                  _c("HngySVG"),
                  _c("HngyTextSVG", { staticStyle: { margin: "0 2px 0 3px" } }),
                  _c(
                    "svg",
                    {
                      staticClass: "icon-font",
                      staticStyle: { width: "80px", height: "70px" },
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-menua-zu4" } })]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("p", [_vm._v("公司：郑州智慧园区科技运营有限公司")]),
      _c("p", [_vm._v("地址：河南省郑州市二七区郑投科技创新园3号楼")]),
      _c("p", [_vm._v("邮箱：contact@techops.cn")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }