import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=61d2d687&"
import script from "./Message.vue?vue&type=script&lang=js&"
export * from "./Message.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\new王涛\\web-enterprise\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61d2d687')) {
      api.createRecord('61d2d687', component.options)
    } else {
      api.reload('61d2d687', component.options)
    }
    module.hot.accept("./Message.vue?vue&type=template&id=61d2d687&", function () {
      api.rerender('61d2d687', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Message.vue"
export default component.exports