var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { no_display: _vm.messageInfo.hide },
      staticStyle: {
        width: "80%",
        "z-index": "9999",
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
      },
      attrs: { id: "message" },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-width": "70%",
            margin: "30px auto",
            "text-align": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "alert alert-info",
              staticStyle: {
                display: "inline-block",
                width: "100%",
                "word-break": "break-word",
                color: "red",
                padding: "0.5em 10px",
              },
              attrs: { role: "alert" },
            },
            [
              _c("i", { staticClass: "glyphicon glyphicon-warning-sign" }),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(_vm._s(_vm.messageInfo.errorInfo)),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }